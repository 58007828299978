import type { Supplier } from "@/services/supplier";
import { usePrepurchase } from "@/hooks/use-prepurchase";
import { formatCurrency } from "@/lib/format-currency";
import type { Lang } from "@/types";
import { useTranslations } from "@/i18n";
import { useShowPricing } from "@/hooks/use-show-pricing";

export const CartSummary = ({ lang, supplier }: { lang: Lang; supplier: Supplier }) => {
  const t = useTranslations(lang);
  const { showPricing } = useShowPricing(supplier);
  const { prepurchase } = usePrepurchase({ supplier });

  if (!prepurchase) {
    return null;
  }

  if (!showPricing) {
    return null;
  }

  return (
    <div className="text-sm">
      <div className="flex justify-between gap-4">
        <span>{t("purchases.field.subTotalWithDiscounts")}</span>
        <span>{formatCurrency(prepurchase.subTotalWithDiscounts, lang, supplier)}</span>
      </div>
      {prepurchase.shipping > 0 && (
        <div className="flex justify-between gap-4">
          <span>{t("purchases.field.shipping")}</span>
          <span>{formatCurrency(prepurchase.shipping, lang, supplier)}</span>
        </div>
      )}
    </div>
  );
};
